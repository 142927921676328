import Providers from 'providers';
import { setDefaultAuthMethod } from 'utils/http';

import Customer from './Customer';
import { CustomerGlobalStyle } from './CustomerApp.styled';

import { CONNECT_TO_EXTERNAL_API } from 'constants/FeatureFlags';

if (CONNECT_TO_EXTERNAL_API) setDefaultAuthMethod('token');

export default function App() {
  return (
    <Providers>
      <CustomerGlobalStyle />
      <Customer />
    </Providers>
  );
}
