import { kebabCase } from 'lodash';

import theme from '../theme';

type CSSVarsType = {
  [key: string]: string | number;
};

function getCSSVarMap() {
  const { colors, fonts, fontSizes } = theme;

  const cssVars: CSSVarsType = {};

  Object.entries(colors).forEach(([name, value]) => {
    cssVars[`--colors-${name}`] = value;
  });

  Object.entries(fonts).forEach(([name, value]) => {
    cssVars[`--fonts-${kebabCase(name)}`] = value;
  });

  Object.entries(fontSizes).forEach(([name, value]) => {
    cssVars[`--fontsizes-${name}`] = `${value}`;
  });

  return cssVars;
}

export default getCSSVarMap;
