/**
 * Sets the environment to use for config package
 */

import { EnvironmentType, setEnv } from 'config';
import { getCSSVarMap } from 'theme';

setEnv(
  (process.env.REACT_APP_CONFIG_USE_EXTERNAL_API as EnvironmentType) ||
    (process.env.REACT_APP_CONFIG_ENV as EnvironmentType) ||
    'local',
);

/**
 * Injects CSS variables into document root
 */
function injectCssVariables(vars: { [key: string]: string | number }) {
  const { documentElement: root } = document;
  Object.entries(vars).forEach(([key, val]) => {
    root.style.setProperty(key, `${val}`);
  });
}

// Just using a copy of the variables from postcss.config.js
// Babel seems to have trouble with imports outside of /src
// We should work on phasing out these variables and move to
// the theme package
const legacyVariables = {
  // '--variableName': 'css value',
  '--page-background': '#191c1f',
  '--page-background-rgb': '26,28,31',

  '--nav-background': '#0d1013',
  '--nav-background-rgb': '13,16,19',

  '--card-background': '#393f45',
  '--card-background-rgb': '57,63,69',

  '--status-neutral': '#2F3B41',
  '--status-neutral-rgb': '47,59,65',

  '--status-alert': '#D6005B',
  '--status-alert-rgb': '214,0,91',

  '--status-warn': '#FFA526',
  '--status-warn-rgb': '255,165,38',

  '--status-pending': '#403257',
  '--status-pending-rgb': '64,50,87',

  '--status-complete': '#078564',
  '--status-complete-rgb': '7,133,100',

  '--accent': '#40bbff',
  '--accent-rgb': '64,187,255',

  '--validated': '#44FFB1',
  '--validated-rgb': '68,255,177',

  '--border': 'rgba(255,255,255,0.2)',
  '--customer-grey-blue': '#f6f6ff',
  '--customer-light-blue': '#E2E0FF',
  '--customer-light-blue-rgb': '226,224,255',
  '--customer-med-blue': '#5F59FF',
  '--customer-med-blue-rgb': '95,89,255',
  '--customer-med-blue-inactive': '#AFACFF',
  '--customer-dark-blue': '#2F2D78',
  '--customer-light-pink': '#FCEEFC',
  '--customer-orange-1': '#FFF6F6',
  '--customer-orange-2': '#FFEFEF',
  '--customer-orange': '#C83F34',
  '--customer-coral': '#FF5D52',
  '--customer-dark-orange': '#A62918',
  '--customer-white-1': '#ffffff',
  '--customer-white-2': '#ebebeb',
  '--customer-grey-1': '#a4a4ad',
  '--customer-grey-2': '#999999',
  '--customer-grey-3': '#666666',
  '--customer-grey-4': '#323232',
  '--customer-black': '#191919',
  '--customer-light-yellow': '#FAEFCB',
  '--customer-med-yellow': '#FAD148',
  '--customer-med-green': '#0F8F4D',
  '--customer-dark-green': '#004521',
  '--customer-med-pink': '#FF9CE0',
  '--customer-dark-pink': '#4D0F3A',
};

injectCssVariables(legacyVariables);
injectCssVariables(getCSSVarMap());
