import { lazy } from 'react';

import { Redirect } from 'react-router-dom';

import AccountDeletePage from '../containers/AccountDeletePage';

import { LazyRoute } from './LazyRoute';
import {
  PublicRoutes,
  ProtectedRoutes,
  publicPaths,
  defaultOnboardingPaths,
  startParkingOnboardingPaths,
  taskItemPaths,
} from './paths';

import { DNS_ID, PRIVACY_ID, TOS_ID } from 'constants/AgreementTypes';

const TermsContainer = lazy(() => import('apps/customer/containers/TermsContainer'));
const HelpRouter = lazy(() => import('apps/customer/components/HelpRouter'));
const ClaimPage = lazy(() => import('apps/customer/containers/Claim'));

// /task handler
const PaymentOverduePage = lazy(() => import('apps/customer/containers/PaymentOverduePage'));

// onboarding
const WelcomePage = lazy(() => import('apps/customer/containers/WelcomePage'));
const StartParkingPage = lazy(() => import('apps/customer/containers/StartParkingPage'));

// signed in
const MembershipContainer = lazy(() => import('apps/customer/containers/MembershipContainer'));
const VisitPage = lazy(() => import('apps/customer/containers/VisitPage'));
const PaymentPage = lazy(() => import('apps/customer/containers/PaymentPage'));
const ProfileContainer = lazy(() => import('apps/customer/containers/ProfileContainer'));
const RedeemContainer = lazy(() => import('apps/customer/containers/RedeemContainer'));
const VehicleContainer = lazy(() => import('apps/customer/containers/VehicleContainer'));
const VisitReceiptPage = lazy(() => import('apps/customer/components/VisitReceiptPage'));
const ConfirmMissedEntryPage = lazy(
  () => import('apps/customer/containers/ConfirmMissedEntryPage'),
);
const DashboardPage = lazy(() => import('apps/customer/containers/Dashboard'));
const PromotionsPage = lazy(() => import('apps/customer/containers/Promotions'));
const PassReceiptPage = lazy(() => import('apps/customer/containers/PassReceiptPage'));

export const onboardingRoutesList = ['/welcome'].concat(defaultOnboardingPaths).concat(publicPaths);

export const publicRoutesList = [
  {
    path: PublicRoutes.Terms,
    component: LazyRoute,
    children: <TermsContainer agreementType={TOS_ID} />,
  },
  {
    path: PublicRoutes.Privacy,
    component: LazyRoute,
    children: <TermsContainer agreementType={PRIVACY_ID} />,
  },
  {
    path: PublicRoutes.DoNotSell,
    component: LazyRoute,
    children: <TermsContainer agreementType={DNS_ID} />,
  },
  {
    exact: false,
    path: PublicRoutes.Help,
    component: LazyRoute,
    children: <HelpRouter />,
  },
  {
    exact: false,
    path: PublicRoutes.Claim,
    component: LazyRoute,
    children: <ClaimPage />,
  },
];

export const taskRoutesList = () => [
  {
    exact: true,
    path: taskItemPaths,
    component: LazyRoute,
    children: <PaymentOverduePage />,
  },
];

export const customerRoutesList = ({
  isInviteWelcome,
  isMostRecentVisitOpen,
}: {
  isInviteWelcome?: boolean | null;
  isMostRecentVisitOpen: boolean | null;
}) => [
  // onboarding
  isInviteWelcome
    ? {
        path: '/welcome',
        component: LazyRoute,
        children: <WelcomePage />,
      }
    : null,
  {
    exact: true,
    path: startParkingOnboardingPaths,
    component: LazyRoute,
    children: <StartParkingPage />,
  },
  // signed in
  {
    exact: true,
    path: ProtectedRoutes.ForwardSlash,
    component: LazyRoute,
    children:
      isMostRecentVisitOpen === null ? null : (
        <Redirect
          to={isMostRecentVisitOpen ? ProtectedRoutes.VisitPage : ProtectedRoutes.DashboardPage}
        />
      ),
  },
  {
    path: ProtectedRoutes.VisitPage,
    component: LazyRoute,
    exact: true,
    children: <VisitPage />,
  },
  {
    path: ProtectedRoutes.PaymentPage,
    component: LazyRoute,
    children: <PaymentPage />,
  },
  {
    exact: true,
    path: ProtectedRoutes.ProfilePage,
    component: LazyRoute,
    children: <ProfileContainer />,
  },
  {
    exact: true,
    path: ProtectedRoutes.AccountDeletePage,
    component: LazyRoute,
    children: <AccountDeletePage />,
  },
  {
    path: ProtectedRoutes.MembershipPage,
    component: LazyRoute,
    children: <MembershipContainer />,
  },
  {
    path: ProtectedRoutes.RedeemPage,
    component: LazyRoute,
    children: <RedeemContainer />,
  },
  {
    path: ProtectedRoutes.VehiclePage,
    component: LazyRoute,
    children: <VehicleContainer />,
  },
  {
    path: ProtectedRoutes.VisitReceiptPage,
    component: LazyRoute,
    exact: true,
    children: <VisitReceiptPage />,
  },
  {
    path: ProtectedRoutes.DashboardPage,
    component: LazyRoute,
    exact: true,
    children: isMostRecentVisitOpen === null ? null : <DashboardPage />,
  },
  {
    path: ProtectedRoutes.ConfirmMissedEntryPage,
    component: LazyRoute,
    exact: true,
    children: <ConfirmMissedEntryPage />,
  },
  {
    path: ProtectedRoutes.PromotionsPage,
    component: LazyRoute,
    exact: true,
    children: <PromotionsPage />,
  },
  {
    path: ProtectedRoutes.PassReceiptPage,
    component: LazyRoute,
    exact: true,
    children: <PassReceiptPage />,
  },
];
