const { REACT_APP_CONFIG_ENV } = process.env;

export const curentEnv = REACT_APP_CONFIG_ENV;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const isLocal =
  (typeof window !== 'undefined' && window.location.hostname === 'localhost') ||
  (typeof process !== 'undefined' && process.env.REACT_APP_CONFIG_ENV === 'local');

// running app locally, but using development API
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const isLocalDev = REACT_APP_CONFIG_ENV === 'development' && isLocal;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const isDevelopment = REACT_APP_CONFIG_ENV === 'development';

// running app locally, but using staging API
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const isLocalStaging = REACT_APP_CONFIG_ENV === 'staging' && isLocal;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const isStaging = REACT_APP_CONFIG_ENV === 'staging';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const isProd = REACT_APP_CONFIG_ENV === 'production';

// connect to external API by Setting to true and defining the api env bellow
export const CONNECT_TO_EXTERNAL_API = !!process.env.REACT_APP_CONFIG_USE_EXTERNAL_API;

export const ADMIN_RATE_SCHEDULES_ENABLED = true;
export const ADMIN_DISABLE_SUB_CANCEL_REASON = isLocal; // why only local?
export const ENABLE_PENDING_MENU_NOTIFICATIONS = false;
export const ENABLE_INTAKE_PARTNER_SELECTION = true;
export const ADMIN_LANE_DELETE_ENABLED = isLocal;
export const ADMIN_VIOLATION_OPERATOR_FILTER_ENABLED = false;
export const ADMIN_ALLOW_DELETE_GATES = isDevelopment || isLocal;
export const ENABLE_RUM = true;
export const ENABLE_RUM_CUSTOMER_ONLY = false;
export const SPECIALIST_VALET_TIMER = false;
export const HIDE_NEW_VEND_GATE = false;
export const DISPLAY_ONBOARIND_PERCENT_ENTERPRISE = isDevelopment || isLocal;
export const ENABLE_BOULDER_RATES = true;
export const SHOW_VALET_LP_SELECT = false;
export const ENABLE_LANE_EVENTS = isLocal || isDevelopment;
export const NEW_INVOICE_FLOW = true;
export const HIDE_PAST_PAYMENTS = isProd;
export const ENABLE_EXIT_BY_INPUT = true;
export const USE_NEW_INVOICE_PREVIEW = true;
export const ENABLE_EXEMPTION_TAX_COMPONENTS = !isProd;
export const SHOW_CUSTOMER_PAGE_FOR_MANAGER_LEADER_ADMIN = !isProd;
export const ADMIN_PARKING_PASS_LOCATIONS = isDevelopment || isLocal;
export const SELF_SERVICE_PARKING_PASS = !isProd;

export const QA_SITE_SURVEY_ADDRESS_ARRAY = [
  { address: '1314 7th St, Santa Monica, CA 90401', lat: 34.020101, lng: -118.49326555 },
  { address: '501 Broadway, Nashville, TN 37203', lat: 36.16346979558982, lng: -86.76171840911704 },
  {
    address: '500 E 1st St, Fort Worth, TX 76102',
    lat: 32.75806276721787,
    lng: -97.32930948452254,
  },
  {
    address: '2535 Rivard St, Detroit, MI 48207',
    lat: 42.345383878266865,
    lng: -83.04229802083397,
  },
  { address: '1770 13th St, Boulder, CO 80302', lat: 40.015055, lng: -105.2799799 },
  { address: '298 Gough St, San Francisco, CA 94102', lat: 37.77603, lng: -122.42258 },
  { address: '227 W 27th St, New York, NY 10001', lat: 40.74664916488252, lng: -73.99421431831814 },
];

export const Flags = {
  ADMIN_RATE_SCHEDULES_ENABLED,
  ADMIN_DISABLE_SUB_CANCEL_REASON,
  ENABLE_PENDING_MENU_NOTIFICATIONS,
  ENABLE_INTAKE_PARTNER_SELECTION,
  ADMIN_LANE_DELETE_ENABLED,
  ADMIN_VIOLATION_OPERATOR_FILTER_ENABLED,
  ADMIN_ALLOW_DELETE_GATES,
  ADMIN_PARKING_PASS_LOCATIONS,
  ENABLE_RUM,
  ENABLE_RUM_CUSTOMER_ONLY,
  SPECIALIST_VALET_TIMER,
  HIDE_NEW_VEND_GATE,
  DISPLAY_ONBOARIND_PERCENT_ENTERPRISE,
  REACT_APP_CONFIG_ENV,
  ENABLE_BOULDER_RATES,
  SHOW_VALET_LP_SELECT,
  ENABLE_LANE_EVENTS,
  NEW_INVOICE_FLOW,
  ENABLE_EXIT_BY_INPUT,
  HIDE_PAST_PAYMENTS,
  QA_SITE_SURVEY_ADDRESS_ARRAY,
  ENABLE_EXEMPTION_TAX_COMPONENTS,
  SHOW_CUSTOMER_PAGE_FOR_MANAGER_LEADER_ADMIN,
};
