/**
 * Make API calls to fetch transactions data repeatedly.
 */

import { useEffect } from 'react';

import { fetchCustomerVisits } from 'apps/customer/state/visit';
import { getConfig } from 'config';

const { DATA_REFRESH_INTERVAL } = getConfig();
let intervalId: any;

export default function useVisitPolling({ interval = DATA_REFRESH_INTERVAL, enabled = true }) {
  useEffect(() => {
    if (enabled) {
      intervalId = setInterval(() => {
        fetchCustomerVisits();
      }, interval);
    }

    return () => clearInterval(intervalId);
  }, [interval, enabled]);
}
