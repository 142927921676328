import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import theme, { WebFonts } from 'theme';

export default function Providers({ children }: { children?: React.ReactNode }) {
  return (
    <ThemeProvider theme={theme}>
      <WebFonts />
      <BrowserRouter>{children}</BrowserRouter>
    </ThemeProvider>
  );
}
