import development from './envs/development';
import local from './envs/local';
import production from './envs/production';
import shared from './envs/shared';
import staging from './envs/staging';

const environments = {
  local,
  development,
  staging,
  production,
};

export type EnvironmentType = 'local' | 'development' | 'staging' | 'production';

type ConfigType = {
  CONFIG_ENV?: EnvironmentType;

  DATA_REFRESH_INTERVAL: number;

  GOOGLE_OAUTH_CLIENT_ID: string;

  SQUARE_CLIENT_ID: string;
  SQUARE_CALLBACK_URL?: string;

  SITE_API_BASE_URL?: string;

  STRIPE_PUBLISHABLE_KEY: string;

  HELP_URL: string;
};

type OverrideFnArgs =
  | Partial<ConfigType>
  | ((env: EnvironmentType | null, config: ConfigType) => Partial<ConfigType>);

class Configurator {
  config: ConfigType;

  environment: EnvironmentType | null;

  constructor() {
    this.config = { ...shared };
    this.environment = null;
  }

  setEnv = (env: EnvironmentType): void => {
    const { environment: prevEnv } = this;
    this.environment = env;

    if (prevEnv !== env) {
      this.build();
    }
  };

  build = (): void => {
    if (!this.environment) {
      throw new Error('`setEnv()` must be called with config environment');
    }
    this.config = {
      ...shared,
      ...environments[this.environment],
      CONFIG_ENV: this.environment,
    };
    Object.freeze(this.config);
  };

  getConfig = (): ConfigType => {
    if (!this.environment) {
      throw new Error('`setEnv()` must be called with config environment');
    }
    return this.config;
  };

  getEnv = (): EnvironmentType | null => this.environment;

  override = (args: OverrideFnArgs): void => {
    const config = this.getConfig();
    const environment = this.getEnv();
    if (typeof args === 'function') {
      this.config = {
        ...config,
        ...args(environment, config),
      };
    }
    this.config = {
      ...config,
      ...args,
    };
  };
}

export default Configurator;
